"use client";
import { validatePasswordAndSetHeader } from "@/lib/bandshop-password";
import { useTranslations } from "next-intl";
import { useCallback, useState, useTransition } from "react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Text } from "@/components/ui/text";

export default function PasswordPage(props: { params: { shopSlug: string; locale: string } }) {
	const t = useTranslations("BandshopPassword");
	const [isError, setError] = useState(false);
	const [transitionLoading, startTransition] = useTransition();
	// an extra state is needed because the pending state of `useTransition` could be set to false before the page is fully reloaded
	const [loading, setLoading] = useState(false);

	const submit = useCallback(
		(formData: FormData) => {
			startTransition(async () => {
				setLoading(true);
				setError(false);
				const res = await validatePasswordAndSetHeader(
					props.params.shopSlug,
					formData.get("password") as string | undefined
				);
				if (res) {
					location.reload();
				} else {
					setLoading(false);
					setError(true);
				}
			});
		},
		[props.params.shopSlug]
	);

	return (
		<main className="flex min-h-screen flex-col items-center justify-center">
			<div className="mx-4 flex max-w-80 flex-col items-center gap-1">
				<Text weight={"semibold"} size={"2xl"} asChild>
					<h1>{t("title")}</h1>
				</Text>
				<Text className="pb-6 text-center">{t("description")}</Text>
				<form className="flex size-full flex-col gap-3" action={submit}>
					<div className="flex w-full flex-col gap-1">
						<Input
							name="password"
							type="password"
							autoComplete="off"
							required
							autoFocus
							disabled={loading}
							placeholder={t("password-label")}
						/>
						{isError && <Text className="text-sm text-red-light">{t("wrong-password")}</Text>}
					</div>
					<Button loading={transitionLoading || loading} type="submit">
						{t("submit")}
					</Button>
				</form>
			</div>
		</main>
	);
}
